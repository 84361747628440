import {Helmet} from 'react-helmet-async';
import {connect} from 'react-redux';
import {formatPhone} from 'goodeggs-formatters';
import React, {Component} from 'react';

import MarketLayout from 'web/components/market_layout';

import TermsLayout from '../components/terms_layout';

class ReferralPage extends Component {
  static reducer(state, action) {
    return MarketLayout.reducer(state, action);
  }

  renderTerms() {
    return (
      <div>
        <h3>Promotions &amp; Referral Program</h3>
        <p>Effective date: July 2020</p>
        <h4>Terms of Use</h4>
        <ul>
          <li>
            Only one offer per household, defined as persons living at the same residential address.
          </li>
          <li>
            All offers expire after thirty days and are limited to new customers only unless
            otherwise specified.
          </li>
          <li>
            Good Eggs may suspend or terminate the Referral Program or a user’s ability to
            participate in the Referral Program at any time for any reason.
          </li>
          <li>
            Good Eggs reserves the right to review and investigate all referral activities and to
            suspend accounts or modify referrals or other offers as deemed fair and appropriate.
          </li>
          <li>
            We reserve the right to suspend accounts, cancel orders that have been placed, remove
            Good Eggs Credits if we notice any activity that we believe is abusive or fraudulent.
          </li>
          <li>
            Rewards and incentives do not apply to alcohol products and are not redeemable for cash
            value.
          </li>
          <li>
            All special offers are subject to any other terms, conditions and restrictions set forth
            on the Services or presented in connection with the special offer.
          </li>
        </ul>
        <h4>Changes to Terms</h4>
        <p>
          Good Eggs reserves the right, in its sole discretion, to change the Terms under which
          goodeggs.com is offered. The most current version of the Terms will supersede all previous
          versions. Good Eggs encourages you to periodically review the Terms to stay informed of
          our updates.
        </p>
        <h4>Contact Us</h4>
        <p>We welcome your questions or comments regarding the Terms:</p>
        <p>
          Good Eggs
          <br />
          2000 Maritime St.
          <br />
          Oakland, California 94607
        </p>
        <p>Email Address: {this.props.giftCardsPage.emailAddress}</p>
        <p>Telephone number: {formatPhone(this.props.giftCardsPage.phoneNumber)}</p>
        <p>Effective Date: July 2020</p>
      </div>
    );
  }

  render() {
    return (
      <MarketLayout
        categories={this.props.categories}
        foodhub={this.props.foodhub}
        user={this.props.user}
        disableUpcomingOrdersBanner
      >
        <Helmet>
          <title>Promotions & Referral Program Terms of Use | Good Eggs</title>
        </Helmet>

        <TermsLayout currentSlug="referral">{this.renderTerms()}</TermsLayout>
      </MarketLayout>
    );
  }
}

ReferralPage.pageName = 'Promotions & Referral Program Terms of Use';

export default connect((state) => state)(ReferralPage);
